import axios from 'axios';

export default class Supplies {

	static axiosCancelToken = axios.CancelToken.source();
	
	static getProductsList = (type, search) => {
		this.newAxiosCancelToken();

		return axios.get('api/supplies/products-list', {
			params: {search: search, type: type},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static addOrder = (type, station_id, lines) => {
		this.newAxiosCancelToken();

		return axios.post('api/supplies/add-order', {
			station_id: station_id,
			lines: lines,
			type: type
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data.errors;
		})
	}

	
	static getOrders = (limit = 10, with_lines = false) => {
		this.newAxiosCancelToken();

		return axios.get('api/supplies/orders-list', {
			params: {
				limit: limit,
				with_lines: with_lines
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static getBCOrdersToCheckPending = () => {
		this.newAxiosCancelToken();

		return axios.get('api/supplies/bc-orderstc-list', {
			params: {},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static getBCOrderToCheck = (id) => {
		this.newAxiosCancelToken();

		return axios.get('api/supplies/bc-ordertc/' + id, {
			params: {},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static sendBCOrderToCheck = (id, products) => {
		this.newAxiosCancelToken();

		return axios.post('api/supplies/bc-ordertc-savecheck/' + id, {
			order: id,
			products: products,
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data.errors;
		})
	}

	static getPendingPlanning = () => {
		this.newAxiosCancelToken();

		return axios.get('api/supplies/get-pending-planning', {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}